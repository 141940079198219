/* div::-webkit-scrollbar {
    display: none;
} */
.main_top_area {
    width: 100%;
    height: 9vh;
    display: flex;
    background: #2130b8;
    align-items: center;
}
.main_body_area {
    width: 100%;
    height: 100vh;
    display: flex;
    background: white;
    flex-direction: row;
    justify-content: center;
}
.main_body_user_area {
    width: 400px;
    height: 100%;
    display: flex;
    position: relative;
    background: white;
    box-shadow: 0 0 25px #8c8c8c45;
    z-index: 1;
    transition: all 0.5s ease-out;
}
.main_body_location_area {
    width: 100%;
    height: 100%;
    display: flex;
    background: #fcfcfc;
    justify-content: center;
}
.lucas_doctor_logo{
    height: 65%;
    padding-left: 57px;
    padding-right: 46px;
    border-right: 1px solid #414fd4;
}
.main_top_title_lab {
    color: white;
    font-size: max(2.03vh, 16px);
    margin-left: 45px;
    font-family: SemiBold;
    margin-bottom: 0px;
}
.main_top_button_area {
    margin-left: auto;
    margin-right: 32px;
}