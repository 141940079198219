
@media (prefers-color-scheme: dark) {
  .modal-content { background: #303030 !important; color: #fff !important; }

}

@media (prefers-color-scheme: light) {
  .modal-content { background: #fafafa  !important;; color: rgba(0, 0, 0, 0.87)  !important;; }
}
.modal-body p {
    word-wrap: break-word;
}
#anydesk_modal {
    width: 350px
}
.info_div {
    display: flex;
    flex-direction: row;
}
.p_value {
    color: blue
}
.p_name {
    margin-right: 1rem;
}
#location_video_modal {
    max-width: 100vh;
}
.modal_title{
    font-family: Poppins;
}
#emoji_modal{
    max-width: 800px;
}

#emoji_modal .modal-content .modal-body {
    justify-content: space-around;
    display: flex;
}

  